import React from 'react';

import edit from '../../../../../assets/img/edit.png';

import LoaderAnimation from '../../../../../base/components/LoaderAnimation/LoaderAnimation';

import styles from './GoalOverview.module.css';

const GoalOverview = ({
  history,
  statsLoading,
  organisation,
  stats,
  isLoggedIn,
  strings
}) => (
  <div className={styles.container}>
    {isLoggedIn ? (
      <img
        src={edit}
        className={styles.editGoalButton}
        onClick={() => history.push('/goal/edit')}
        role="presentation"
        alt="Edit goal"
      />
    ) : null}
    <div className={styles.goalOverviewContainer}>
      <div className={styles.logoContainer}>
        <img
          className={styles.logo}
          alt="Company Logo"
          src={organisation.image_filename}
        />
      </div>
      <div className={styles.detailsContainer}>
        <div className={`${styles.subTitle}`}>{strings.INFO_ORGANISATION}</div>
        <div className={styles.description}>{organisation.description}</div>

        <div>
          <div className={styles.valueContainer}>
            <div className={styles.subTitle}>{strings.COLLECTED}</div>
            {statsLoading ||
            !(
              stats &&
              (stats.totalEurosCollected || stats.totalEurosCollected === 0)
            ) ? (
              <div className={styles.loaderContainer}>
                <LoaderAnimation size="small" />
              </div>
            ) : (
              <div className={styles.currentValue}>
                €{stats.totalEurosCollected.toFixed(2)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default GoalOverview;
