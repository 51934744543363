import React from 'react';

import arrowDown from '../../../../assets/img/black-arrow-down.svg';

import styles from './SelectDropdown.module.css';

export default props => {
  const {
    label,
    onChange,
    value,
    name,
    options,
    className,
    labelStyle,
    noValueSelectedClassName,
    placeholder,
    error
  } = props;

  return (
    <>
      <div className={labelStyle || styles.userDetailTitle}>{label} *</div>
      <select
        type="backend"
        onChange={event => onChange(event.target.value)}
        value={value}
        options={options}
        className={
          value === null || value === ''
            ? `${className} ${styles.dropdown} ${noValueSelectedClassName}`
            : `${className} ${styles.dropdown}`
        }
        style={{ backgroundImage: `url(${arrowDown})` }}
      >
        <option value="">{placeholder || 'Please select value'}</option>
        {options.map((option, index) =>
          option && option instanceof Object ? (
            <option
              key={`dropdown-${name}-${index.toString()}`}
              value={option.value}
            >
              {option.label}
            </option>
          ) : (
            <option key={`dropdown-${name}-${index.toString()}`} value={option}>
              {option}
            </option>
          )
        )}
      </select>
      {error ? <span className={styles.error}>{error}</span> : ''}
    </>
  );
};
