import React from 'react';
import { withRouter } from 'react-router-dom';

import bufflLogo from '../../../assets/img/buffl.svg';

import styles from './AppBar.module.css';

const AppBar = ({
  organisation,
  isLoggedIn,
  strings,
  onLogoClick,
  auth,
  language,
  setLanguage
}) => (
  <header className={`${styles.container} ${auth ? styles.authContainer : ''}`}>
    <div
      className={
        onLogoClick ? `${styles.left} ${styles.logoClick}` : styles.left
      }
      onClick={onLogoClick}
      role="presentation"
    >
      <img className={styles.logo} src={bufflLogo} alt="BUFFL logo" />
      <div className={`app-bar-page-title roboto-light-font ${styles.title}`}>
        {auth ? strings.ORGANISATIONS : strings.DASHBOARD}
      </div>
    </div>
    <div className={styles.right}>
      <ul className={styles.menu}>
        <>
          <span
            className={`${styles.languageOption} ${
              language === 'NL' ? styles.languageOptionActive : ''
            }`}
            role="presentation"
            onClick={() => setLanguage('NL')}
          >
            NL
          </span>
          <span
            className={`${styles.languageOption} ${
              language === 'FR' ? styles.languageOptionActive : ''
            }`}
            role="presentation"
            onClick={() => setLanguage('FR')}
          >
            FR
          </span>
        </>
        {organisation && isLoggedIn ? (
          <li
            className={styles.logout}
            role="presentation"
            onClick={() => {
              localStorage.removeItem('@buffl:auth-token');
              window.location.href = '/organisations/login';
            }}
          >
            {strings.LOGOUT}
          </li>
        ) : null}
      </ul>
    </div>
  </header>
);

export default withRouter(AppBar);
