const ObjectID = require('bson-objectid');

export default (graph, features) => {
  if (
    graph.features &&
    graph.features.length &&
    features &&
    graph.features.length !== features.length
  ) {
    return [...graph.features].map(() => ({
      id: ObjectID().toString(),
      active: true
    }));
  }

  return [...features].map((f, i) => {
    const unfilteredId =
      graph &&
      graph.unFilteredFeatures &&
      graph.unFilteredFeatures[i] &&
      graph.unFilteredFeatures[i].id
        ? graph.unFilteredFeatures[i].id
        : null;
    const unfilteredF = {
      ...f,
      ...(unfilteredId ? { id: unfilteredId } : {})
    };
    if (!unfilteredId) {
      delete unfilteredF.id;
    }
    return unfilteredF;
  });
};
