import React, { useState } from 'react';

import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import AppleButton from './AppleButton';

import tickWhite from '../../../../../../assets/img/tick-white.svg';
import closeIcon from '../../../../../../assets/img/delete.svg';

import styles from './SocialSignUp.module.css';

const SocialSignUp = props => {
  const {
    location,
    translations,
    language,
    refererUserId,
    addQueryParam,
    socialLoginSuccessful,
    isOrganisationUserRegister
  } = props;

  const [showPopup, setShowPopup] = useState({
    active: false,
    loginFunction: () => {}
  });
  const [acceptTerms, setAcceptTerms] = useState(false);

  let isProductionEnv = true;
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.REACT_APP_ENV === 'qa'
  ) {
    isProductionEnv = false;
  }

  let iosDevice = false;
  if (
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.match(/iPhone|iPad|iPod/i)
  ) {
    iosDevice = true;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translations.BECOME_MEMBER}</div>
      <div className={styles.buttonsContainer}>
        {!iosDevice ? (
          <FacebookButton
            location={location}
            translations={translations}
            language={language}
            refererUserId={refererUserId}
            facebookLoginSuccessful={socialLoginSuccessful}
            setShowPopup={setShowPopup}
            isProductionEnv={isProductionEnv}
            isOrganisationUserRegister={isOrganisationUserRegister}
          />
        ) : null}
        <GoogleButton
          translations={translations}
          language={language}
          refererUserId={refererUserId}
          googleLoginSuccessful={socialLoginSuccessful}
          setShowPopup={setShowPopup}
        />
        <AppleButton
          translations={translations}
          language={language}
          refererUserId={refererUserId}
          appleLoginSuccessful={socialLoginSuccessful}
          setShowPopup={setShowPopup}
          isProductionEnv={isProductionEnv}
        />
      </div>
      <div
        className={styles.proceedWithEmail}
        role="presentation"
        onClick={() => {
          addQueryParam('step', 'emailSignUp');
        }}
      >
        {translations.REGISTER_WITH}{' '}
        <span className={styles.proceedWithEmailBold}>
          {translations.EMAIL_ADDRESS}
        </span>
      </div>
      {showPopup && showPopup.active ? (
        <>
          <div className={styles.backgroundBlur} />
          <div className={styles.popup}>
            <div className={styles.popupDescription}>
              {translations.TO_PROCEED_ACCEPT_TERMS_PRIVACY}
            </div>
            <div className={styles.acceptInputContainer}>
              <div
                className={`${styles.checkmark} ${
                  acceptTerms ? styles.checkmarkActive : ''
                }`}
                role="presentation"
                onClick={() => setAcceptTerms(prev => !prev)}
              >
                {acceptTerms ? (
                  <img src={tickWhite} alt="Tick" className={styles.tick} />
                ) : null}
              </div>
              <div className={styles.termsText}>
                {translations.I_ACCEPT_THE}{' '}
                <a
                  className={styles.termsLink}
                  target="_blank"
                  href="https://www.buffl.be/legal/terms-conditions"
                  rel="noopener noreferrer"
                >
                  {translations.TERMS_AND_CONDITIONS_REFERRAL}{' '}
                </a>
                {translations.AND}{' '}
                <a
                  className={styles.termsLink}
                  target="_blank"
                  href="https://www.buffl.be/legal/privacy-policy"
                  rel="noopener noreferrer"
                >
                  {translations.PRIVACY_POLICY_REFERRAL}
                </a>
              </div>
            </div>
            <div
              className={`${styles.confirmButton} ${
                !acceptTerms ? styles.confirmButtonDisabled : ''
              }`}
              role="presentation"
              onClick={() => {
                if (acceptTerms) {
                  setShowPopup(prev => ({
                    ...prev,
                    active: false
                  }));
                  showPopup.loginFunction();
                  setAcceptTerms(false);
                }
              }}
            >
              {translations.CONFIRM}
            </div>
            <img
              src={closeIcon}
              alt="close"
              className={styles.closeIcon}
              role="presentation"
              onClick={() => {
                setShowPopup({
                  active: false,
                  loginFunction: () => {}
                });
                setAcceptTerms(false);
              }}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SocialSignUp;
