import React from 'react';

import styles from './SignUpExplanation.module.css';

const SignUpExplanationUserReferral = props => {
  const { translations } = props;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {translations.WELCOME_TO_BUFFL_YOUR_OPINION_MATTERS}
      </h1>
      <p className={styles.text}>{translations.JOIN_OUR_COMMUNITY}</p>
      <p className={styles.text}>{translations.COUPLE_MINUTES_FIRST_REWARD}</p>
      <p>
        <div className={styles.subTitle}>{translations.STEP_1_TITLE_USER}</div>
        <div className={styles.text}>
          {translations.STEP_1_DESCRIPTION_USER}
        </div>
      </p>
      <p>
        <div className={styles.subTitle}>{translations.STEP_2_TITLE_USER}</div>
        <div className={styles.text}>
          {translations.STEP_2_DESCRIPTION_USER}
        </div>
      </p>
      <p>
        <div className={styles.subTitle}>{translations.STEP_3_TITLE_USER}</div>
        <div className={styles.text}>
          {translations.STEP_3_DESCRIPTION_USER}
        </div>
      </p>
      <p className={styles.text}>
        {translations.JOIN_AND}
        <span className={styles.textItalic}>
          {' '}
          {translations.DECIDE_WHATS_NEXT}
        </span>
      </p>
    </div>
  );
};

export default SignUpExplanationUserReferral;
