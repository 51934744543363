import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

import styles from './Ranking.module.css';
import './Pagination.css';

const Ranking = ({ data, strings }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 5;

  const dataSortedByValue =
    data && data.length ? data.sort((a, b) => b.value - a.value) : [];
  const paginatedData =
    dataSortedByValue && dataSortedByValue.length
      ? dataSortedByValue.reduce((acc, curr, index) => {
          const pageIndex = Math.floor(index / pageSize);
          if (!acc[pageIndex]) {
            acc[pageIndex] = [];
          }
          acc[pageIndex].push({ ...curr, index });
          return acc;
        }, {})
      : null;

  let pages = 0;
  if (data && data.length) {
    pages = Math.ceil(data.length / pageSize);
  }

  const changePage = pageNumber => {
    setCurrentPage(pageNumber.selected);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.title} roboto-bold-font`}>
        {strings.RANKING}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.table}>
          {paginatedData && currentPage >= 0 && paginatedData[currentPage]
            ? paginatedData[currentPage].map(ranking => (
                <div
                  className={styles.tableRow}
                  key={`ranking-item-${ranking.index.toString()}`}
                >
                  <div className={`${styles.index} roboto-bold-font`}>
                    {ranking.index + 1}
                  </div>
                  <div className={styles.name}>{ranking.label}</div>
                  <div className={styles.tokens}>
                    {ranking.value} {strings.TOKENS}
                  </div>
                </div>
              ))
            : null}
          <div className={styles.noDataAvailable}>
            {!data || !data.length ? strings.NO_DATA_AVAILABLE : null}
          </div>
          {pages ? (
            <div>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                initialPage={currentPage}
                forcePage={currentPage}
                disableInitialCallback
                onPageChange={changePage}
                containerClassName="pagination pagination-organisation"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Ranking;
