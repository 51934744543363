import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './Footer.module.css';

const Footer = ({ strings }) => (
  <footer className={styles.contactContainer}>
    <a
      className={styles.button}
      href="https://www.buffl.be/community/contact-us"
      target="new"
    >
      {strings.CONTACT_US}
    </a>
  </footer>
);

export default withRouter(Footer);
