export default (
  level,
  record,
  licenceStartDateParsed,
  licenceEndDateParsed,
  currentMoment = new Date()
) => {
  let surveysThatShouldPublishPerDay = null;
  let amountOfDaysInLicense = null;

  if (level === 1) {
    const oneDay = 24 * 60 * 60 * 1000;
    amountOfDaysInLicense =
      licenceStartDateParsed && licenceEndDateParsed
        ? Math.round(
            Math.abs((licenceStartDateParsed - licenceEndDateParsed) / oneDay)
          )
        : null;

    surveysThatShouldPublishPerDay =
      record &&
      record.permissions &&
      record.permissions.surveysIncludedInLicense &&
      amountOfDaysInLicense
        ? record.permissions.surveysIncludedInLicense / amountOfDaysInLicense
        : null;
  }

  if (level === 2) {
    surveysThatShouldPublishPerDay = null;
  }

  const currentMomentNullHours = currentMoment.setUTCHours(0, 0, 0, 0);
  const currentMomentDate = new Date(currentMomentNullHours);

  const licenceEndDateToCompareNullHours = licenceEndDateParsed
    ? licenceEndDateParsed.setUTCHours(0, 0, 0, 0)
    : null;
  const licenceEndDateToCompare =
    licenceEndDateToCompareNullHours &&
    new Date(licenceEndDateToCompareNullHours);

  const dateLeftToExpire =
    licenceEndDateParsed && licenceEndDateToCompare
      ? currentMomentDate.getTime() - licenceEndDateToCompare.getTime()
      : null;
  const daysLeftToExpire = dateLeftToExpire
    ? dateLeftToExpire / (1000 * 3600 * 24)
    : null;
  const daysLeftToExpireInteger =
    daysLeftToExpire !== null ? Math.floor(daysLeftToExpire) : null;

  const licenceDaysElapsed =
    surveysThatShouldPublishPerDay !== null && amountOfDaysInLicense !== null
      ? amountOfDaysInLicense - -1 * daysLeftToExpireInteger
      : null;

  const surveysThatShouldHaveBeenPublished =
    licenceDaysElapsed !== null
      ? licenceDaysElapsed * surveysThatShouldPublishPerDay
      : null;

  let touchpoint = null;
  let touchpointGraphWidth = null;
  // Customers with license should always show touchpoints value
  if (
    (!record.surveysPublished || record.surveysPublished === 0) &&
    amountOfDaysInLicense !== null
  ) {
    touchpoint = 0;
    touchpointGraphWidth = touchpoint;
  } else if (
    record &&
    record.permissions &&
    record.permissions.surveysIncludedInLicense &&
    record.permissions.surveysIncludedInLicense > 0 &&
    record.surveysPublished
  ) {
    touchpoint = Math.round(
      (record.surveysPublished / surveysThatShouldHaveBeenPublished) * 100
    );

    touchpointGraphWidth = touchpoint;
    if (touchpointGraphWidth > 100) {
      touchpointGraphWidth = 100;
    }
  }

  return {
    touchpoint,
    touchpointGraphWidth,
    daysLeftToExpireInteger, // If this value is positive, licence is expired
    dateLeftToExpire
  };
};
