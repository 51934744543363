import React from 'react';

import copy from '../../../../../assets/img/copy.svg';

import styles from './InviteMembers.module.css';

const InviteMembers = ({ organisation, strings }) => {
  const copyLinkToClipboard = value => {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.setAttribute('id', 'goalurl');
    document.getElementById('goalurl').value = value;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  return (
    <div>
      <div className={styles.title}>
        {strings.INVITE_MEMBERS_AND_COLLECT_TOGETHER}
      </div>
      <div style={{ marginBottom: '20px' }}>
        {strings.DO_YOU_WANT_TO_COLLECT_WITH_YOUR_ASSOCIATION}
      </div>
      <div className={styles.subTitle}>{strings.HOW_DOES_IT_WORK}</div>
      <div style={{ marginBottom: '20px' }}>
        {strings.MEMBERS_COLLECTING_EXPLANATION}
      </div>
      <div style={{ marginBottom: '15px' }}>
        {strings.HELP_YOUR_ASSOCIATION_TODAY}
      </div>
      <div className={styles.linkInputContainer}>
        <div className={styles.linkInput}>{organisation.link}</div>
        <div
          className={styles.copyIconContainer}
          style={{ backgroundImage: `url(${copy})` }}
          onClick={() => copyLinkToClipboard(organisation.link)}
          role="presentation"
        />
      </div>
    </div>
  );
};

export default InviteMembers;
