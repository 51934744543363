import gql from 'graphql-tag';

export const GET_ORGANISATION_DETAILS = gql`
  query Organisation($token: String) {
    organisation(token: $token) {
      id
      email
      name
      contact_person {
        first_name
        last_name
        phone
      }
      savings_target
      description
      image_filename
      user
      language
      link
    }
  }
`;

export const GET_STATISTICS = gql`
  query Organisation($token: String) {
    statistics(token: $token) {
      numberOfUserOnTheApp
      totalEurosCollected
      totalActiveEurosCollected
      totalPassiveEurosCollected
      eurosReceivedFromBuffl
      ranking {
        label
        value
      }
      eurosCollectedInLastMonths {
        label
        value
      }
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation Update(
    $name: String!
    $contactPersonFirstName: String!
    $contactPersonLastName: String!
    $contactPersonPhone: String!
    $savingsTarget: Float!
    $description: String!
    $imageFilename: String
  ) {
    update(
      input: {
        name: $name
        contactPersonFirstName: $contactPersonFirstName
        contactPersonLastName: $contactPersonLastName
        contactPersonPhone: $contactPersonPhone
        savingsTarget: $savingsTarget
        description: $description
        imageFilename: $imageFilename
      }
    ) {
      organisation {
        id
        email
        name
        contact_person {
          first_name
          last_name
          phone
        }
        savings_target
        description
        image_filename
      }
    }
  }
`;

export const GET_BLOCK_UPLOAD_SAS = gql`
  query GetBlockUploadSAS($organisationId: String!) {
    blockUploadSAS(input: { organisationId: $organisationId }) {
      token
      uri
      filename
    }
  }
`;

export const BUY_COUPONS_FROM_TOKENS = gql`
  mutation BuyCouponsFromTokens($organisationId: String!) {
    buyCouponsFromTokens(input: { organisationId: $organisationId }) {
      done
    }
  }
`;
