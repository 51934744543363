import React, { useState } from 'react';

import LineChart from './components/LineChart/LineChart';

import styles from './MoneyGraph.module.css';

const MoneyGraph = ({ data, strings }) => {
  const [moneyGraphContainer, setMoneyGraphContainer] = useState(null);

  const months = [
    strings.JANUARY,
    strings.FEBRUARY,
    strings.MARCH,
    strings.APRIL,
    strings.MAY,
    strings.JUNE,
    strings.JULY,
    strings.AUGUST,
    strings.SEPTEMBER,
    strings.OCTOBER,
    strings.NOVEMBER,
    strings.DECEMBER
  ];

  const dataToMap = data && data.length ? [...data].reverse() : [];

  const { eurosCollectedInLastMonths } = dataToMap.reduce(
    (acc, monthItem) => {
      const label = monthItem.label.split('-');

      const item = {
        label: `${months[label[0] - 1]} ${label[1]}`,
        value: monthItem.value + acc.totalValueCount
      };

      return {
        ...acc,
        eurosCollectedInLastMonths: [
          ...acc.eurosCollectedInLastMonths,
          { ...item }
        ],
        totalValueCount: item.value
      };
    },
    { eurosCollectedInLastMonths: [], totalValueCount: 0 }
  );

  return (
    <div
      className={styles.container}
      ref={elem => setMoneyGraphContainer(elem)}
    >
      <div className={styles.title}>{strings.MONEY_GRAPH}</div>
      {moneyGraphContainer &&
      eurosCollectedInLastMonths &&
      eurosCollectedInLastMonths.length ? (
        <LineChart
          data={eurosCollectedInLastMonths}
          height="400"
          parent={moneyGraphContainer}
        />
      ) : null}
      <div className={styles.noDataAvailable}>
        {!eurosCollectedInLastMonths || eurosCollectedInLastMonths.length === 0
          ? strings.NO_DATA_AVAILABLE
          : null}
      </div>
    </div>
  );
};

export default MoneyGraph;
