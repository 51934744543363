import React, { useState } from 'react';

import SelectDropdown from '../../../SelectDropdown/SelectDropdown';

import tickWhite from '../../../../../../assets/img/tick-white.svg';

import styles from './EmailSignUp.module.css';

const EmailSignUp = props => {
  const {
    componentError,
    submitEmailSignUp,
    fields,
    setFieldValue,
    registrationStarted,
    translations,
    errorFields
  } = props;

  const [acceptTerms, setAcceptTerms] = useState(false);

  return (
    <div className={styles.form}>
      {componentError && (
        <span className={styles.componentError}>{componentError}</span>
      )}
      <form>
        <span className={styles.label}>
          <span className={styles.labelName}>
            {translations.EMAIL_ADDRESS_CAPITALIZED} *
          </span>
        </span>
        <input
          id="email"
          name="email"
          type="email"
          className={`${styles.input} register-email-input`}
          value={fields.email}
          onChange={event => {
            setFieldValue('email', event.target.value);
          }}
          autoComplete="email"
        />
        {errorFields.email.length ? (
          <span className={styles.error}>
            {translations[errorFields.email]}
          </span>
        ) : (
          ''
        )}

        <span className={styles.label}>
          <span className={styles.labelName}>{translations.PASSWORD} *</span>
        </span>
        <input
          type="password"
          className={`${styles.input} register-password-input`}
          value={fields.password}
          onChange={event => {
            setFieldValue('password', event.target.value);
          }}
          autoComplete="new-password"
        />
        {errorFields.password.length ? (
          <span className={styles.error}>
            {translations[errorFields.password]}
          </span>
        ) : (
          ''
        )}

        <span className={styles.label}>{translations.REPEAT_PASSWORD} *</span>
        <input
          type="password"
          className={`${styles.input} register-repeat-password-input`}
          value={fields.repeatPassword}
          onChange={event => {
            setFieldValue('repeatPassword', event.target.value);
          }}
          autoComplete="new-password"
        />
        {errorFields.repeatPassword.length ? (
          <span className={styles.error}>
            {translations[errorFields.repeatPassword]}
          </span>
        ) : (
          ''
        )}

        <span className={styles.label}>{translations.DATE_OF_BIRTH} *</span>
        <input
          type="date"
          className={`${styles.input} ${styles.dateInput}`}
          value={fields.date_of_birth}
          onChange={event => {
            setFieldValue('date_of_birth', event.target.value);
          }}
        />
        {errorFields.date_of_birth.length ? (
          <span className={styles.error}>
            {translations[errorFields.date_of_birth]}
          </span>
        ) : (
          ''
        )}

        <SelectDropdown
          name="gender"
          value={fields.gender}
          label={translations.GENDER}
          onChange={value => setFieldValue('gender', value)}
          options={[
            { label: translations.MALE, value: 'M' },
            { label: translations.FEMALE, value: 'F' },
            { label: translations.OTHER, value: 'O' }
          ]}
          className={`${styles.input} register-gender-input`}
          labelStyle={styles.label}
          noValueSelectedClassName={styles.noValueSelected}
          placeholder={translations.CHOOSE_A_GENDER}
          error={translations[errorFields.gender]}
        />

        <div className={styles.acceptInputContainer}>
          <div
            className={`${styles.checkmark} ${
              acceptTerms ? styles.checkmarkActive : ''
            }`}
            role="presentation"
            onClick={() => setAcceptTerms(prev => !prev)}
          >
            {acceptTerms ? (
              <img src={tickWhite} alt="Tick" className={styles.tick} />
            ) : null}
          </div>
          <div className={styles.termsText}>
            {translations.I_ACCEPT_THE}{' '}
            <a
              className={styles.termsLink}
              target="_blank"
              href="https://www.buffl.be/legal/terms-conditions"
              rel="noopener noreferrer"
            >
              {translations.TERMS_AND_CONDITIONS_REFERRAL}{' '}
            </a>
            {translations.AND}{' '}
            <a
              className={styles.termsLink}
              target="_blank"
              href="https://www.buffl.be/legal/privacy-policy"
              rel="noopener noreferrer"
            >
              {translations.PRIVACY_POLICY_REFERRAL}{' '}
            </a>
            <span style={{ marginLeft: '2px' }}>*</span>
          </div>
        </div>

        {!registrationStarted ? (
          <div
            onClick={event => {
              if (acceptTerms) {
                submitEmailSignUp(event);
              }
            }}
            role="presentation"
            type="submit"
            className={`${styles.button} ${
              !acceptTerms ? styles.buttonDisabled : ''
            } logindata-next-button`}
          >
            {translations.CREATE_ACCOUNT}
          </div>
        ) : (
          <div className="user-app-button-loading user-app-button-loading-grey">
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
      </form>
    </div>
  );
};

export default EmailSignUp;
