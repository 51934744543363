import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import qs from 'query-string';

import { LOGIN_FACEBOOK } from '../../../../../../graphql/Auth';

import facebookIcon from '../../../../../../assets/img/facebook-icon.svg';

import styles from './SocialSignUp.module.css';

const FacebookButton = props => {
  const {
    location,
    translations,
    language,
    refererUserId,
    facebookLoginSuccessful,
    setShowPopup,
    isProductionEnv,
    isOrganisationUserRegister
  } = props;

  const [error, setError] = useState(false);

  useEffect(
    () => {
      if (location) {
        const queryHash = location.hash ? qs.parse(location.hash) : null;
        const querySearch = location.search ? qs.parse(location.search) : null;

        if (queryHash && queryHash.access_token) {
          handleFacebookCallback({
            accessToken: queryHash.access_token || null
          });
        } else if (querySearch && querySearch.access_token) {
          handleFacebookCallback({
            accessToken: querySearch.access_token || null
          });
        }
      }
    },
    [location]
  );

  const [login, { loading }] = useMutation(LOGIN_FACEBOOK, {
    onCompleted: ({ loginFacebook: data }) => {
      if (data && data.errors && data.errors.length >= 1) {
        setError(true);
      } else if (data && data.loginToken) {
        setError(false);
        facebookLoginSuccessful(data.loginToken);
        localStorage.removeItem('refererUserId');
      }
    },
    onError: () => setError(true)
  });

  const handleFacebookCallback = response => {
    if (response && response.accessToken) {
      let parentUserId = null;
      const storageReferalId = localStorage.getItem('refererUserId');

      if (refererUserId) {
        parentUserId = refererUserId;
      } else if (storageReferalId) {
        parentUserId = storageReferalId;
      }

      login({
        variables: {
          accessToken: response.accessToken,
          language: language || 'NL',
          ...(parentUserId ? { refererUserId: parentUserId } : {})
        }
      });
    } else {
      setError(true);
    }
  };

  let redirectUri = '';
  if (isProductionEnv) {
    redirectUri = isOrganisationUserRegister
      ? 'https://app.buffl.be/users/register/organisation'
      : 'https://app.buffl.be/users/register';
  } else {
    redirectUri = isOrganisationUserRegister
      ? 'https://test.buffl.be/users/register/organisation'
      : 'https://test.buffl.be/users/register';
  }

  return (
    <div>
      <FacebookLogin
        appId="1458049280965946"
        fields="email"
        callback={handleFacebookCallback}
        render={renderProps => (
          <div
            role="presentation"
            onClick={() => {
              if (refererUserId) {
                localStorage.setItem('refererUserId', refererUserId);
              }

              setShowPopup({
                active: true,
                loginFunction: () => renderProps.onClick()
              });
            }}
            className={`${styles.socialButton} ${
              loading ? styles.loading : ''
            }`}
          >
            <img
              src={facebookIcon}
              className={styles.facebookIcon}
              alt="Facebook icon"
            />
            <span>{translations.CONTINUE_WITH_FACEBOOK}</span>
          </div>
        )}
        redirectUri={redirectUri}
        onFailure={() => {
          setError(true);
        }}
        responseType="token"
      />
      {error ? (
        <div className={styles.error}>
          {translations.REGISTER_ERROR_FACEBOOK}
        </div>
      ) : null}
    </div>
  );
};

export default FacebookButton;
