import React from 'react';
import { withRouter } from 'react-router-dom';

import './LoaderAnimation.css';

const LoaderAnimation = ({ size }) => (
  <div
    className={`loading-animation ${size ? `loading-animation-${size}` : ''}`}
  >
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default withRouter(LoaderAnimation);
