import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import GoalOverview from './components/GoalOverview/GoalOverview';
import InviteMembers from './components/InviteMembers/InviteMembers';
import Ranking from './components/Ranking/Ranking';
import Data from './components/Data/Data';
import MoneyGraph from './components/MoneyGraph/MoneyGraph';

import styles from './DashboardPage.module.css';

import { GET_STATISTICS } from '../../../graphql/Organisation';

const DashboardPage = ({
  history,
  organisation,
  match,
  isLoggedIn,
  strings
}) => {
  const { data: { statistics } = {}, loading, error } = useQuery(
    GET_STATISTICS,
    {
      variables: {
        ...(match && match.params && match.params.token
          ? { token: match && match.params && match.params.token }
          : {})
      },
      fetchPolicy: 'network-only'
    }
  );

  return (
    <div className={styles.container}>
      <div className={`${styles.title} roboto-bold-font`}>
        {organisation.name}
      </div>
      <div className={styles.section}>
        <GoalOverview
          organisation={organisation}
          history={history}
          statsLoading={loading || !statistics || error}
          stats={statistics}
          isLoggedIn={isLoggedIn}
          strings={strings}
        />
      </div>
      <div className={styles.section}>
        <InviteMembers organisation={organisation} strings={strings} />
      </div>
      <div className={styles.overviewTabsSeparator} />
      {loading || !statistics || error ? (
        <div>
          <div
            className={`${styles.section} ${
              styles.sectionLoading
            } loader-container`}
          />
          <div
            className={`${styles.section} ${
              styles.sectionLoading
            } loader-container`}
          />
          <div
            className={`${styles.section} ${
              styles.sectionLoading
            } loader-container`}
          />
        </div>
      ) : (
        <>
          <div className={styles.section}>
            <Data data={statistics} strings={strings} />
          </div>
          <div className={styles.section}>
            <MoneyGraph
              data={statistics.eurosCollectedInLastMonths}
              strings={strings}
            />
          </div>
          <div className={styles.section}>
            <Ranking data={statistics.ranking} strings={strings} />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardPage;
