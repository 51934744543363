import React, { useState } from 'react';

import RecordAction from '../RecordAction/RecordAction';

import {
  businessConfigurationTypes,
  recordActionPopups
} from '../../helpers/constants';

import startIcon from '../../../../assets/img/star.svg';
import amDelete from '../../../../assets/img/accountmanagement/am-delete.svg';
import amDownload from '../../../../assets/img/accountmanagement/am-download.svg';
import amEdit from '../../../../assets/img/accountmanagement/am-edit.svg';
import amTeams from '../../../../assets/img/accountmanagement/am-teams.svg';
import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';

import styles from './AccountManagementRecord.module.css';
import calculateLicenceExpirationTouchpoints from '../../../../helpers/calculateLicenceExpirationTouchpoints/calculateLicenceExpirationTouchpoints';

const getAccountManagementColor = percent => {
  if (percent < 30) {
    return '#f61553';
  }
  if (percent >= 30 && percent <= 75) {
    return '#f9ad2c';
  }
  if (percent > 75) {
    return '#7fc26c';
  }
  return null;
};

export default ({
  level,
  parent,
  record,
  setActivePopup,
  onRecordClick,
  accountManagement,
  isAdmin,
  actionsAllowed,
  higherRecords,
  onSelectRecord,
  onGlobalExportClick
}) => {
  const [isRecordHovered, setIsRecordHovered] = useState(false);

  let shouldBudgetOverviewBeAvailable = false;
  if (record && record.budgetAvailable === true && isAdmin) {
    shouldBudgetOverviewBeAvailable = true;
  }

  let licenceStartDateParsed = null;
  let licenceEndDateParsed = null;

  if (level === 1) {
    licenceStartDateParsed =
      record && record.licenceStartDate
        ? new Date(record.licenceStartDate)
        : null;
    licenceEndDateParsed =
      record && record.licenceEndDate ? new Date(record.licenceEndDate) : null;
  }

  if (level === 2) {
    const parentRecordInformation = higherRecords.find(r => r.id === parent);

    if (parentRecordInformation) {
      licenceStartDateParsed = parentRecordInformation.licenceStartDate
        ? new Date(parentRecordInformation.licenceStartDate)
        : null;
      licenceEndDateParsed = parentRecordInformation.licenceEndDate
        ? new Date(parentRecordInformation.licenceEndDate)
        : null;
    }
  }

  const {
    touchpoint,
    touchpointGraphWidth,
    daysLeftToExpireInteger, // If this value is positive, licence is expired
    dateLeftToExpire
  } = calculateLicenceExpirationTouchpoints(
    level,
    record,
    licenceStartDateParsed,
    licenceEndDateParsed
  );

  const licenceStartDateString = licenceStartDateParsed
    ? `${licenceStartDateParsed.getDate()}/${licenceStartDateParsed.getMonth() +
        1}/${licenceStartDateParsed.getFullYear()}`
    : '';
  const licenceEndDateString = licenceStartDateParsed
    ? `${licenceEndDateParsed.getDate()}/${licenceEndDateParsed.getMonth() +
        1}/${licenceEndDateParsed.getFullYear()}`
    : '';

  const allowedToSelectRecord =
    isAdmin ||
    level === 2 ||
    (level === 1 &&
      accountManagement &&
      accountManagement.manager &&
      accountManagement.manager.some(m => m.id === record.id));

  return (
    <tr
      onClick={() => {
        if (allowedToSelectRecord && isRecordHovered) {
          onSelectRecord();
        }
      }}
      className={`${styles.record} ${
        allowedToSelectRecord && isRecordHovered ? styles.isRecordHovered : ''
      }`}
      onMouseEnter={() => {
        if (allowedToSelectRecord) setIsRecordHovered(true);
      }}
      onMouseLeave={() => {
        if (allowedToSelectRecord) setIsRecordHovered(false);
      }}
    >
      <td className={styles.rowContainer}>
        {record.isManager ? (
          <img
            src={startIcon}
            alt="Starred user"
            className={styles.starredUser}
          />
        ) : null}
        <span className={styles.nameContainer}>{record.name}</span>
      </td>
      <td className={`${styles.rowContainer} ${styles.touchpointsContainer}`}>
        {touchpoint !== null ? (
          <>
            <div className={styles.touchpointNumber}>{touchpoint}%</div>
            <div className={styles.barContainer}>
              <div
                className={styles.bar}
                style={{
                  width: `${touchpointGraphWidth}%`,
                  backgroundColor: getAccountManagementColor(
                    touchpointGraphWidth
                  )
                }}
              />
            </div>
          </>
        ) : null}
      </td>
      {level === 1 ? (
        <>
          <td className={styles.rowContainer}>{licenceStartDateString}</td>
          <td className={styles.rowContainer}>
            {licenceEndDateString}
            {daysLeftToExpireInteger &&
            daysLeftToExpireInteger < 0 &&
            Math.abs(daysLeftToExpireInteger) < 60 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License will expire in {daysLeftToExpireInteger * -1}{' '}
                  {daysLeftToExpireInteger === 1 ? 'day' : 'days'}
                </div>
              </div>
            ) : null}
            {dateLeftToExpire === 0 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License expires today
                </div>
              </div>
            ) : null}
            {daysLeftToExpireInteger !== null && daysLeftToExpireInteger > 0 ? (
              <div className={styles.warningTooltipContainer}>
                <img
                  className={styles.warningTooltipIcon}
                  alt="Touchpoints tooltip"
                  src={amWarning}
                />
                <div className={styles.warningTooltipPopup}>
                  <div className={styles.warningTooltipPopupTriangle} />
                  License expired
                </div>
              </div>
            ) : null}
          </td>
        </>
      ) : null}
      <td className={styles.rowContainer}>
        <div
          className={styles.actionButtonsContainer}
          onMouseEnter={() => {
            if (allowedToSelectRecord) setIsRecordHovered(false);
          }}
          onMouseLeave={() => {
            if (allowedToSelectRecord) setIsRecordHovered(true);
          }}
        >
          <RecordAction
            label={level === 1 ? 'Business challenges' : 'Members'}
            icon={amTeams}
            onClick={() => {
              if (onRecordClick) {
                document.documentElement.scrollTop = 0;
                onRecordClick(record);
              }
            }}
          />
          {shouldBudgetOverviewBeAvailable ? (
            <RecordAction
              label="License overview"
              icon={amDownload}
              onClick={async () =>
                onGlobalExportClick(recordActionPopups.LICENSE_OVERVIEW, record)
              }
            />
          ) : null}
          {actionsAllowed ? (
            <>
              <RecordAction
                label="Edit"
                icon={amEdit}
                onClick={() => {
                  let businessSettings = [];
                  if (
                    record.businessSettings &&
                    record.businessSettings.length
                  ) {
                    businessSettings = record.businessSettings.map(settings => {
                      let settingsRecords = [];
                      if (settings.recordOtherOptionSelected) {
                        settingsRecords = [{ id: 'Other', name: 'Other' }];
                      }
                      if (settings.records && settings.records.length) {
                        settingsRecords = [
                          ...settingsRecords,
                          ...settings.records.map(sR => ({
                            id: sR.id,
                            name: sR.name
                          }))
                        ];
                      }

                      return {
                        type: settings.type,
                        records: settingsRecords,
                        recordCustomName: settings.recordCustomName || '',
                        ...(settings.type ===
                        businessConfigurationTypes.CHALLENGE
                          ? { description: settings.description }
                          : {})
                      };
                    });
                  }
                  setActivePopup({
                    type: recordActionPopups.EDIT,
                    record: {
                      ...record,
                      licenceStartDate:
                        record &&
                        record.licenceStartDate &&
                        new Date(record.licenceStartDate),
                      licenceEndDate:
                        record &&
                        record.licenceEndDate &&
                        new Date(record.licenceEndDate),
                      businessSettings
                    }
                  });
                }}
              />
              <RecordAction
                label="Delete"
                icon={amDelete}
                onClick={() => {
                  setActivePopup({ type: recordActionPopups.DELETE, record });
                }}
              />
            </>
          ) : null}
        </div>
      </td>
    </tr>
  );
};
