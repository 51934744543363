export default {
  CONFIRM: 'Confirme',
  TERMS: 'Termes',
  SURVEY: 'Enquête',
  YOUR_INFO: 'Tes info',
  THANKS: 'Merci',
  BY_CLICK_AGREE:
    "En cliquant sur accepter et continuer, j'accepte les conditions suivantes :  les conditions d'utilisation et la politique de confidentialité",
  AGREE_AND_CONTINUE: 'Accepte et continue',
  BACK: 'Précédent',
  NEXT: 'Suivant',
  I_AM_AWARE_OF_THE: 'J’ai pris connaissance des',
  READ_AND_UNDERSTAND: '',
  THE_TERMS_AND_CONDITIONS:
    'Les conditions générales et la politique de confidentialité',
  TERMS_AND_CONDITIONS: 'Conditions générales et politique de confidentialité',
  AND_AGREE_BY_CHECKING_THIS_BOX: 'et je les confirme en cochant cette case:',
  AND_CONFIRM_I_AGREE: 'et je confirme que je suis d’accord:',
  I_AGREE: 'Je suis d’accord:',
  GOVERNED_BY_BUFFL_POLICY:
    'Cette enquête est soumise à la politique de confidentialité de BUFFL.\nVeuillez consulter notre',
  PRIVACY_POLICY: 'politique de confidentialité',
  BEFORE_PROCEEDING: 'avant de continuer.',
  AGREE_TO_PROCEED: 'Êtes-vous d’accord pour continuer ?',
  YES_I_AGREE: 'Oui, je suis d’accord',
  NO_I_DO_NOT_AGREE: 'Non, je ne suis pas d’accord',
  PLEASE_SELECT_VALUE: 'Sélectionne une valeur',
  PLEASE_ENTER: 'Saisis',
  WE_ARE_ALMOST_THERE:
    'Nous y sommes presque, nous aimerions apprendre à te connaître un peu mieux',
  FEMALE: 'Femme',
  MALE: 'Homme',
  OTHER: 'Autre',
  GENDER: 'Sexe',
  AGE: 'Âge',
  POSTAL_CODE: 'Code postal',
  CREATE_USER_PROFILE: 'Crée un profil utilisateur',
  PASSWORD: 'Mot de passe',
  REPEAT_PASSWORD: 'Confirme ton mot de passe',
  PLEASE_REPEAT_PASSWORD: 'Merci de confirmer ton mot de passe',
  WE_NEED_YOUR_EMAIL: 'Nous avons besoin de ton adresse email.',
  SO_WE_CAN_SEND_YOU_EMAIL:
    '.. Ainsi nous pouvons t’envoyer un email de vérification pour valider ta participation',
  EMAIL: 'Email',
  SO_YOU_CAN_BE_CONTACTED_IF_WIN:
    '.. Si tu gagnes un prix, tu seras contacté par email',
  YES_I_WANT_TO_BE_CONTACTED_IF_WIN:
    'Oui, je souhaite être contacté si je gagne',
  SO_YOU_CAN_BE_CONTACTED_FOR_THE_NEXT_PARTS:
    '..Tu peux donc être contacté pour la suite',
  YES_I_WANT_TO_BE_CONTACTED_FOR_NEXT_SURVEYS:
    'Oui, je souhaite être contacté pour de prochaines enquêtes',
  SO_YOU_CAN_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    '.. Ainsi tu pourras être ajouté à la liste d’utilisateurs',
  YES_I_WANT_TO_BE_ADDED_TO_MAILING_LIST_OF_CUSTOMER:
    'Oui, je souhaite être ajouté à la liste d’utilisateurs',
  SO_YOU_CAN_HAVE_OWN_BUFFL_LOGIN:
    '..Pour que tu puisses obtenir ton propre login BUFFL.',
  WHAT_IS_BUFFL_ABOUT: 'Qui est BUFFL?',
  AT_BUFFL_YOU_CAN_HELP_DETERMINE_PRODUCTS:
    "Chez BUFFL, tu peux contribuer à déterminer à quoi devraient ressembler les produits, services et marques de demain. Sur l'application mobile BUFFL, tu peux participer en remplissant plusieurs sondages et être récompensé(e) avec des chèques-cadeaux!",
  THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE: 'Merci!',
  THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE:
    "L'enquête que tu cherches n'est plus disponible.",
  CHECKOUT_AWESOME_BUFFL_APP:
    'Va voir la fantastique application BUFFL pour encore plus de sondages et être récompensé par d’incroyables prix.',
  START: 'Commence',
  ALL_FIELDS_ARE_REQUIRED: 'Tous les champs sont obligatoires',
  PASSWORDS_ARE_NOT_SAME: ' Tes mots de passe ne correspondent pas',
  EMAIL_IS_REQUIRED: 'Ton adresse email est obligatoire',
  WE_HAVE_SENT_YOU_EMAIL_WITH_CONFIRMATION_LINK:
    'Nous t’avons envoyé un email avec un lien de confirmation. Il est possible que notre email se trouve dans ton dossier spam.',
  RESULT_ACCEPTED: 'Réponse acceptée.',
  YOUR_RESULTS_HAS_BEEN_CONFIRMED: 'Tes réponses ont été confirmées.',
  SURVEY_FAILED: 'Merci beaucoup pour ta participation !',
  UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE: 'Merci !',
  UNFORTUNATELY: 'Malheureusement',
  UNFORTUNATELY_ON_FAILED_PAGE: 'Malheureusement',
  UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE: 'Malheureusement',
  YOU_ARE_NOT_ELIGIBLE: 'Ton profil n’est pas éligible pour cette enquête.',
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED:
    "Merci de vouloir partager votre opinion. Nous avons déjà assez de répondants avec un profil similaire au vôtre. Ne vous inquiétez pas, de nouvelles enquêtes seront bientôt mises en ligne. Voulez-vous être le premier à être informé ? Alors téléchargez l'application BUFFL dès maintenant !",
  DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE:
    "Merci de vouloir partager votre opinion. Nous avons déjà suffisamment de répondants pour cette enquête. Ne vous inquiétez pas, de nouvelles enquêtes seront bientôt mises en ligne. Voulez-vous être le premier à être informé ? Alors téléchargez l'application BUFFL dès maintenant !",
  RESULT_CONFIRMED: 'Réponse confirmée.',
  YOUR_RESULT_WAS_ALREADY_REGISTERED_SUCESSFULY:
    'Ta réponse a déjà été enregistrée !',
  IF_YOU_LIKE_PARTICIPATING_IN_MORE_SURVEYS:
    "Si tu souhaites prendre part à plus d'enquêtes et gagner des prix fantastiques, installe l'application BUFFL !",
  CONFIRM_EMAIL: 'Confirme ton email.',
  MINIMAL_CHARACTERS: 'Le nombre minimum de caractères',
  MAXIMAL_CHARACTERS: 'Le nombre maximum de caractères',
  START_A_NEW_SURVEY: 'Commence une nouvelle enquête',
  READ_FULL_TERMS_AND_CONDITIONS:
    "Lis ici l'intégralité des conditions générales",
  READ_FULL_PRIVACY_POLICY: 'Lis ici notre politique de confidentialité',

  WELCOME: 'Bienvenue',
  WELCOME_STEP: 'Bienvenue',
  USER_PROFILE: 'Profil',
  CONFIRMATION: 'Confirmation',
  WE_MAKE_YOU_PLEASANT_HERE:
    'Nous voulons te rendre la vie aussi agréable que possible tout au long de cette enquête. Sélectionne donc ci-dessous la langue dans laquelle tu souhaites continuer.',
  YOU_ARE_NOT_JANE: 'Enchanté de vous rencontrer !',
  SOCIO_DEMO: `Avant le début de l'enquête, nous avons besoin de quelques données sur vous. Cela nous permet une meilleure compréhension des personnes qui participent à l'enquête.\n\nNe vous inquiétez pas, BUFFL traite vos données avec précaution et les réponses que vous donnez sont anonymes.`,
  WE_NEED_FEW_MORE_DETAILS_FROM_YOU:
    "Avant le début de l'enquête, nous avons besoin de quelques données sur vous. Cela nous permet une meilleure compréhension des personnes qui participent à l'enquête. Ne vous inquiétez pas, BUFFL traite vos données avec précaution et les réponses que vous donnez sont anonymes.",
  REST_ASSURED_RESPONSES_ARE_ANONYMIZED:
    'Rassure-toi, BUFFL traitera ces données avec soin et les réponses que tu fourniras seront rendues anonymes.',
  YOU_ARE: 'Tu as...',
  YOU_ARE_A: 'Tu es...',
  YOUR_POSTCODE_IS: 'Ton code postal est...',
  YEARS_OLD: '...ans.',
  HOW_OLD_ARE_YOU: 'Quel âge as-tu ?',
  MALE_SHORT: 'M',
  FEMALE_SHORT: 'F',
  ENTER_POSTCODE: 'Vul je postcode in', // not french but nowhere in use
  INTRODUCTION: `Es-tu prêt?\n\nAu cours de cette enquête, nous te poserons quelques questions. Nous sommes intéressés par tes réponses personnelles, il n'y a donc pas de mauvaise réponse.\n\nCette enquête te prendra environ {time} minutes de ton temps.\n\nNous te souhaitons un bon amusement.\n\nLet’s go!`,
  READY_TO_LAUNCH: 'Es-tu prêt?',
  DURING_SURVEY_WILL_PRESENT_SURVEY:
    "Au cours de cette enquête, nous te poserons quelques questions. Nous sommes intéressés par tes réponses personnelles, il n'y a donc pas de mauvaise réponse.",
  THIS_SURVEY_TAKES:
    'Cette enquête te prendra environ {time} minutes de ton temps.',
  WE_HOPE_YOU_WILL_ENJOY: 'Nous te souhaitons un bon amusement.',
  LETS_GO: 'Let’s go!',
  JUST_THIS: 'Merci beaucoup pour ta participation!',
  WE_CAN_REACH_YOU:
    'Pourrais-tu confirmer ton adresse email ? Nous en avons besoin pour valider tes réponses',
  WOULD_YOU_LIKE_TO_RECEIVE_NEW_SURVEYS:
    'Aimerais-tu recevoir de nouvelles  enquêtes.',
  YOU_WANT_TO_BE_CONTACTED: 'Informez-moi si j’ai gagné un prix.',
  A_DONKEY_DOESNT_BUMP:
    "Un âne ne trébuche pas deux fois sur la même pierre. Crée rapidement un profil d'utilisateur et gagne du temps pour participer à de futures enquêtes ou concours",
  CREATE_A_BUFFL_USER_PROFILE: "Crée un profil d'utilisateur BUFFL",
  BUFFL_HAS_DOZENS_OF_RESEARCHES:
    "BUFFL lance des dizaines d'enquêtes par semaine sur toutes sortes de sujets auxquelles tu peux participer. Il y en a donc pour tous les goûts.",
  COMPLETED: `Tu veux découvrir les derniers produits innovants et partager ton opinion, gagner des jetons et avoir une chance de gagner des prix fantastiques?\n\nAlors, installe ensuite l’application BUFFL, disponible dans l’App Store et le Google Play Store.`,
  WOULD_YOU_BE_INTERESTED_GIVING_AN_OPINION:
    'Tu veux découvrir les derniers produits innovants et partager ton opinion, gagner des jetons et avoir une chance de gagner des prix fantastiques?',
  YOUR_ANSWERS_WILL_BE_PROCESSED_SECURELY:
    'Tes réponses sont traitées de manière sécurisée et anonyme.',
  INSTALL_THE_BUFFL_APP:
    'Alors, installe ensuite l’application BUFFL, disponible dans l’App Store et le Google Play Store.',
  WOULD_YOU_STAY_UP_OF_LATEST_NEWS_VIA_MAIL:
    'Tu préfères rester informé des dernières enquêtes par email ou tu souhaites simplement laisser tes coordonnées pour gagner un prix? Alors indique-les ici!',
  CHOOSE_PASSWORD: 'Choisis ton mot de passe',
  ENTER_YOUR_PASSWORD: 'Entre ton mot de passe',
  CONFIRM_PASSWORD: 'Confirme ton mot de passe',
  OPEN_MAILBOX_CONFIRM_ANSWER:
    'Ouvre ta boîte mail et confirme tes réponses et ton adresse électronique',
  CHECK_SPAM_FOLDER: 'Assure-toi également de vérifier ton dossier spam.',
  THANK_YOU: 'Merci de ta participation.',
  MORE_IMPACT: 'Vous souhaitez avoir plus d’impact ?',
  WE_GOT_YOUR_ANSWERS: 'Nous avons bien reçu tes réponses. Merci !',
  WE_ARE_BUFFL: 'Nous sommes BUFFL.',
  THROUGH_YOUR_PARTICIPATION:
    "Par ta participation à ce genre d'enquêtes, tu décides à quoi doivent ressembler les produits, les services et les marques. Veux-tu participer davantage et avoir toujours une chance de gagner de beaux cadeaux ? Alors n'oublie pas de télécharger l'application BUFFL et de nous donner de tes nouvelles",
  YOU_HAVE_ALREADY_PARTICIPATED: 'Tu as déjà participé à cette enquête',
  SHARE_YOUR_OPINION: 'Partage ton opinion dans l’application BUFFL',
  CONTRIBUTE_TO_INNOVATIONS_OF_TOMORROW: 'Contribue aux innovations de demain',
  BE_REWARDED:
    'Soit récompensé par des chèques-cadeaux de tes marques préférées',
  REVIEW_ONE:
    'Cette appli est géniale car elle te pose des questions intéressantes et en plus tu peux gagner des prix très chouettes ou vachement cool!',
  REVIEW_TWO:
    'Une appli cool qui te permet d’exprimer ton avis et qui t’offre des points en contrepartie. Au bout d’un moment, tu peux échanger tes points contre des cadeaux. Elle te propose des questionnaires courts, un peu timbrés mais toujours plein d’humour et focalisés sur des questions « je suis speed – je n’ai pas trop le temps ». C’est vraiment idéal pour passer le temps entre deux occupations. En plus, elle m’a déjà permis d’obtenir un bon cadeau d’une valeur de 10 Euros!',
  REVIEW_THREE:
    'Alors que certaines enquêtes sont barbantes et longues, BUFFL te prouve que ça peut être complètement différent. BUFFL te propose des enquêtes et sondages qui tiennent vraiment compte de ton avis. Il s’agit d’un concept d’épargne de jetons qui te permettent d’obtenir de chouettes cadeaux.',
  JOIN_THE_HERD: 'Télécharge notre application ici',
  WHAT_DOES_BUFFL_OFFER:
    "Qu'est-ce que BUFFL a à t'offrir? Gagne des jetons ou soutiens des œuvres de bienfaisance.  Apprends plein de choses grâce à des faits amusants et aux dernières innovations.  Aie un impact sur les innovations de demain!",
  DATE_OF_BIRTH: 'Date de naissance',
  CHOOSE_A_GENDER: 'Indique ton sexe',
  CREATE_ACCOUNT: 'Crée un compte',
  CONGRATULATIONS: 'Félicitations',
  PART_OF_THE_HERD: 'Désormais tu fais partie du troupeau (BUFFL).',
  DOWNLOAD_AND_START_EARNING_TOKENS:
    'Télécharge l’appli et commence à gagner des jetons!',
  DOWNLOAD_THE_APP_NOW: "Téléchargez l'application BUFFL dès maintenant!",
  PLEASE_FILL_FIELD: 'Ce champ est obligatoire',
  PLEASE_ENTER_VALID_EMAIL: 'Entre une adresse email valide',
  PLEASE_ENTER_DATE_OF_BIRTH_RIGHT_FORMAT:
    'Indique ta date de naissance au format correct',
  PLEASE_ENTER_VALID_DATE_OF_BIRTH: 'Entre une date de naissance valide',
  PLEASE_SELECT_GENDER: 'Indique ton sexe',
  PASSWORDS_NOT_SAME: 'Tes mots de passe ne concordent pas',
  EMAIL_ALREADY_USED: 'Cette adresse email est déjà utilisée',
  START_SURVEY: 'Commencez',
  GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS:
    "Dans cette enquête, il est important que vous exprimiez votre opinion. Les mauvaises réponses n'existent pas. Le plus sincère sera le mieux !",
  LOADING_IMAGE_FAILED: "L'image n'a pas pu être chargée.",
  AMOUNT_OF_ANWSERS: 'Nombre de réponses',
  PLEASE_ENTER_VALID_POSTAL_CODE: 'Entrer un code postal valide.',
  OPEN_QUESTION_REMAINING_CHARACTERS: 'caractères restants',
  OPEN_QUESTION_MIN_CHARACTERS: 'min.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT_ON_DECISIONS_BY_PARTICIPATING_IN_SURVEYS:
    'Souhaitez-vous avoir plus d’impact sur les décisions en participant à des enquêtes courtes comme celle-ci ?\n\n+ Nous offrons un bon d’achat pour un week-end familial de 3 jours d’une valeur de 199,90 €.',
  WOULD_YOU_LIKE_TO_HAVE_MORE_IMPACT:
    'Souhaitez-vous avoir plus d’impact sur les décisions en participant à des enquêtes courtes comme celle-ci ?',
  I_AM_NOT_INTERESTED: 'Je ne suis pas intéressé.',
  WELL_KEEP_IN_TOUCH: 'Nous resterons en contact !',
  YOU_HAVE_ALREADY_EARNED_TOKENS_BY_COMPLETING_SURVEYS:
    'Tu as déjà gagné {euro} € en répondant à {surveys} enquêtes. Prêt à réclamer ta récompense?\nTélécharge l’application, enregistre-toi en utilisant {email} et nous transférerons les jetons sur ton compte.',
  WE_HAVE_NOTICED_EMAIL_IS_LINKED_TO_MOBILE_ACCOUNT:
    'Nous avons remarqué que {email} est lié à un compte sur notre application mobile.\nTu recevras {tokens} jetons pour avoir répondu à cette enquête.',
  YOU_HAVE_ALREADY_CLAIMED_THE_REWARD_FOR_THIS_SURVEY:
    'Tu as déjà réclamé la récompense pour cette enquête.',
  SEC: 'sec',
  PROFILING_QUESTION_CONSENT_TITLE: 'Nous souhaitons raccourcir les sondages.',
  PROFILING_QUESTION_CONSENT_DESCRIPTION:
    'Pouvons-nous enregistrer ta réponse à certaines questions? Cela se fait de manière totalement anonyme et garantit que tu ne reverras plus ces questions dans de futurs sondages.',
  PROFILING_QUESTION_YES_CONSENT:
    'Oui, je suis d’accord, je veux des sondages plus courts !',
  PROFILING_QUESTION_NO_CONSENT:
    'Non, je ne souhaite pas vraiment de sondages plus courts.',
  PROFILING_QUESTION_NO_DO_NOT_ASK:
    'Non, ne demande pas à nouveau pour ces questions.',
  PROFILING_QUESTION_NO_DO_NOT_ASK_EVER:
    'Non, ne demande pas à nouveau pour ces questions ou pour d’autres questions.',
  CONVERSION_THANK_YOU: 'Merci de votre participation',
  CONVERSION_NO_CONSENT:
    'Vos réponses sont traitées de manière sécurisée et anonyme.',
  SELECT_ANSWERS: 'Choisis {selectAtLeast} réponses',
  SELECT_AT_LEAST_AND_AT_MOST_ANSWERS:
    'Choisis au moins {selectAtLeast} et au plus {selectAtMost} réponses',

  // TODO: Old implementation
  MATRIX: {
    I_STRONGLY_DISAGREE: 'Je ne suis pas du tout d’accord',
    I_DISAGREE: 'Je ne suis pas d’accord',
    I_AGREE: 'Je suis d’accord',
    I_STRONGLY_AGREE: 'Je suis entièrement d’accord',
    I_NEITHER_AGREE_NOR_DISAGREE: 'Je ne suis ni d’accord ni en désaccord',
    I_SOMEWHAT_DISAGREE: 'Je ne suis pas vraiment d’accord',
    I_SOMEWHAT_AGREE: 'Je suis un peu d’accord'
  },

  MATRIX_I_STRONGLY_DISAGREE: 'Je ne suis pas du tout d’accord',
  MATRIX_I_DISAGREE: 'Je ne suis pas d’accord',
  MATRIX_I_AGREE: 'Je suis d’accord',
  MATRIX_I_STRONGLY_AGREE: 'Je suis entièrement d’accord',
  MATRIX_I_NEITHER_AGREE_NOR_DISAGREE: 'Je ne suis ni d’accord ni en désaccord',
  MATRIX_I_SOMEWHAT_DISAGREE: 'Je ne suis pas vraiment d’accord',
  MATRIX_I_SOMEWHAT_AGREE: 'Je suis un peu d’accord',

  MATRIX_NOT_IMPORTANT_AT_ALL: 'Pas du tout important',
  MATRIX_RATHER_NOT_IMPORTANT: 'Plutôt pas important',
  MATRIX_RATHER_IMPORTANT: 'Plutôt important',
  MATRIX_VERY_IMPORTANT: 'Très important',

  MATRIX_NOT_DIFFICULT_AT_ALL: 'Pas du tout difficule',
  MATRIX_RATHER_NOT_DIFFICULT: 'Plutôt pas difficule',
  MATRIX_RATHER_DIFFICULT: 'Plutôt difficule',
  MATRIX_VERY_DIFFICULT: 'Très difficule',

  MATRIX_NOT_VALUABLE_AT_ALL: 'Pas du tout utile',
  MATRIX_RATHER_NOT_VALUABLE: 'Plutôt pas utile',
  MATRIX_RATHER_VALUABLE: 'Plutôt utile',
  MATRIX_VERY_VALUABLE: 'Très utile',

  MATRIX_NOT_UNIQUE_AT_ALL: 'Pas du tout unique',
  MATRIX_RATHER_NOT_UNIQUE: 'Plutôt pas unique',
  MATRIX_RATHER_UNIQUE: 'Plutôt unique',
  MATRIX_VERY_UNIQUE: 'Très unique',

  MATRIX_CERTAINLY_NOT_ESSENTIAL: 'Certainement pas essentiel',
  MATRIX_RATHER_NOT_ESSENTIAL: 'Plutôt pas essentiel',
  MATRIX_RATHER_ESSENTIAL: 'Plutôt essentiel',
  MATRIX_CERTAINLY_ESSENTIAL: 'Certainement essentiel',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_EUROS_CONVERSION:
    'Vous avez déjà gagné €{EUROS} en répondant à {SURVEYS} enquête(s). Prêt à réclamer votre récompense ? Téléchargez l’application, enregistrez-vous avec {EMAIL} et nous créditerons votre compte des jetons.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_TOKENS_CONVERSION:
    'Vous avez déjà gagné {TOKENS} jetons en répondant à {SURVEYS} enquête(s). Prêt à réclamer votre récompense ? Téléchargez l’application, enregistrez-vous avec {EMAIL} et nous créditerons votre compte des jetons.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_IMPACT_CONVERSION:
    "Prêt(e) à mettre en pratique vos frustrations, vos idées ou vos réflexions sur le thème de ... ? Téléchargez l'application et enregistrez-vous avec {EMAIL}.",
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_PENDING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_EUROS_CONVERSION:
    'Nous avons remarqué que {EMAIL} est lié à un compte sur notre application mobile. Vous recevrez €{EUROS} pour la complétion de cette enquête.',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_TOKENS_CONVERSION:
    'Nous avons remarqué que {EMAIL} est lié à un compte sur notre application mobile. Vous recevrez {TOKENS} jetons pour la complétion de cette enquête..',
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_IMPACT_CONVERSION:
    "Prêt à concrétiser vos frustrations, vos idées ou vos réflexions sur le thème de ... ? Téléchargez l'application et enregistrez-vous avec {EMAIL}.",
  END_OF_THE_SURVEY_THANK_YOU_SCREEN_EXISTING_CUSTOM_CONVERSION: '',

  END_OF_THE_SURVEY_THANK_YOU_TO_WEB_CONVERSION: 'Nous resterons en contact !',

  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_AS: 'Souhaitez-vous continuer en tant que',
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO_NEED_DATA:
    "Vous n'aurez alors plus besoin de saisir à nouveau votre âge, votre sexe et votre code postal.",
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_YES: "Oui, c'est moi !",
  USER_TOKEN_DO_YOU_WANT_TO_CONTINUE_NO: "Non, continuez en tant qu'invité",

  // organisation referral page translations
  SUPPORT_YOUR_ORGANISATION: 'Soutenez votre association', // organisation copy
  JOIN_AND_SAVE_WITH_ORGANISATION:
    'Rejoignez-nous et soutenez votre association !',
  FUNDRAISING_MADE_EASY:
    'Collecter des fonds pour votre association ? C’est plus simple que vous ne le pensez grâce à l’application BUFFL !',
  FOLLOW_STEPS_START_TODAY:
    'Suivez ces 3 étapes faciles et commencez dès aujourd’hui :',
  STEP_1_TITLE_ORGANISATION: 'Étape 1 : Créez un compte',
  STEP_1_DESCRIPTION_ORGANISATION:
    'Remplissez le formulaire ci-contre pour vous inscrire sur l’application BUFFL. Important : seuls les membres ayant complété ce formulaire seront enregistrés comme participants pour leur association.',
  STEP_1_DESCRIPTION_ORGANISATION_ITALIC:
    'Choisissez l’option avec laquelle vous souhaitez vous connecter dans l’application (Google, Facebook ou votre adresse e-mail).',
  STEP_2_TITLE_ORGANISATION: 'Étape 2 : Téléchargez l’application BUFFL',
  STEP_2_DESCRIPTION_ORGANISATION:
    'Rendez-vous sur l’App Store ou le Google Play Store pour télécharger l’application BUFFL. Connectez-vous avec l’option choisie lors de l’inscription (Google, Facebook ou e-mail).',
  STEP_2_DESCRIPTION_ORGANISATION_ITALIC:
    'Important : ne créez pas un nouveau compte.',
  STEP_3_TITLE_ORGANISATION:
    'Étape 3 : Partagez votre opinion et recevez des récompenses',
  STEP_3_DESCRIPTION_ORGANISATION:
    'Pour chaque sondage auquel vous répondez, nous donnons automatiquement 5 % des jetons que vous avez gagnés à votre association – en plus de la récompense que vous recevez pour vous-même. Vous recevez donc 100 % de votre récompense et nous ajoutons 5 % au profit de votre association.',
  WHAT_ARE_YOU_WAITING_FOR: 'Alors, qu’attendez-vous ?',
  START_AND_SAVE_FOR_ORGANISATION:
    'Commencez maintenant et récoltez rapidement des fonds pour votre association !',
  BECOME_MEMBER: 'Rejoignez-nous',
  CONTINUE_WITH_FACEBOOK: 'Continuer avec Facebook',
  CONTINUE_WITH_GOOGLE: 'Continuer avec Google',
  CONTINUE_WITH_APPLE: 'Continuer avec Apple',
  REGISTER_WITH: 'Ou inscrivez-vous avec votre',
  EMAIL_ADDRESS: 'adresse e-mail',
  EMAIL_ADDRESS_CAPITALIZED: 'Adresse e-mail',
  I_ACCEPT_THE_TERMS_REFERRAL:
    'J’accepte les conditions générales et la politique de confidentialité',
  I_ACCEPT_THE: 'J’accepte les',
  TERMS_AND_CONDITIONS_REFERRAL: 'conditions générales',
  AND: 'et',
  PRIVACY_POLICY_REFERRAL: 'la politique de confidentialité',
  TO_PROCEED_ACCEPT_TERMS_PRIVACY:
    'Pour continuer, vous devez accepter les conditions générales et la politique de confidentialité.',
  LOGIN_ERROR_GOOGLE:
    'Une erreur s’est produite lors de la connexion à Google. Réessayez plus tard.',
  REGISTER_ERROR_GOOGLE:
    'Une erreur s’est produite lors de l’inscription avec votre compte Google. Veuillez réessayer plus tard.',
  LOGIN_ERROR_FACEBOOK:
    'Une erreur s’est produite lors de la connexion à Facebook. Réessaie plus tard.',
  REGISTER_ERROR_FACEBOOK:
    'Une erreur s’est produite lors de l’inscription avec votre compte Facebook. Veuillez réessayer plus tard.',
  LOGIN_ERROR_APPLE:
    'Une erreur s’est produite lors de la connexion à Apple. Réessayez plus tard.',
  REGISTER_ERROR_APPLE:
    'Une erreur s’est produite lors de l’inscription avec votre compte Apple. Veuillez réessayer plus tard.',
  REFERRAL_COMPLETE_TITLE: 'Bienvenue!',
  REFERRAL_COMPLETE_DESCRIPTION_DESKTOP:
    'Vous y êtes presque ! Scannez le code QR ci-dessous ou rendez-vous sur l’App Store ou le Google Play Store pour télécharger l’application BUFFL. Connectez-vous et commencez à épargner pour votre association ! Amusez-vous!',
  REFERRAL_COMPLETE_DESCRIPTION_MOBILE:
    'Vous y êtes presque ! Rendez-vous sur l’App Store ou le Google Play Store pour télécharger l’application BUFFL. Connectez-vous et commencez à épargner pour votre association ! Amusez-vous!',
  WELCOME_TO_BUFFL_YOUR_OPINION_MATTERS:
    'Bienvenue chez BUFFL - Votre opinion fait la différence !', // user copy
  JOIN_OUR_COMMUNITY:
    'Rejoignez notre communauté et aidez à façonner un monde qui vous ressemble, avec des produits et services que les gens attendent vraiment. C’est engageant, impactant, et vous êtes récompensé(e) pour votre contribution. Alors, prêt(e) à participer ?',
  COUPLE_MINUTES_FIRST_REWARD:
    'En quelques étapes simples, commencez à gagner des récompenses :',
  STEP_1_TITLE_USER: 'Étape 1 :  Créez votre compte',
  STEP_1_DESCRIPTION_USER:
    'Complétez le formulaire ci-contre pour vous inscrire. Ce sera votre identifiant personnel pour l’application BUFFL. Sélectionnez votre méthode de connexion préférée (Google, Facebook ou e-mail).',
  STEP_2_TITLE_USER: 'Étape 2 :  Téléchargez l’application BUFFL',
  STEP_2_DESCRIPTION_USER:
    'Accédez à la page de téléchargement de l’application BUFFL. Connectez-vous avec l’option choisie lors de l’inscription (Google, Facebook ou e-mail). Attention : ne créez pas de nouveau compte.',
  STEP_3_TITLE_USER:
    'Étape 3 :  Partagez votre opinion et recevez des récompenses',
  STEP_3_DESCRIPTION_USER:
    'Répondez à des enquêtes courtes, gagnez des jetons, et échangez-les contre des bons d’achat, des dons à des associations, ou pour soutenir votre association préférée.',
  JOIN_AND: 'Rejoignez-nous dès aujourd’hui et',
  DECIDE_WHATS_NEXT: 'contribuez à créer l’avenir !'
};
