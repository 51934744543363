import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import GoogleLogin from 'react-google-login';

import { LOGIN_GOOGLE } from '../../../../../../graphql/Auth';

import googleIcon from '../../../../../../assets/img/google-icon.svg';

import styles from './SocialSignUp.module.css';

const GoogleButton = props => {
  const {
    translations,
    language,
    refererUserId,
    googleLoginSuccessful,
    setShowPopup
  } = props;

  const [error, setError] = useState(false);

  const [login, { loading }] = useMutation(LOGIN_GOOGLE, {
    onCompleted: ({ loginGoogle: data }) => {
      if (data && data.errors && data.errors.length >= 1) {
        setError(true);
      } else if (data && data.loginToken) {
        setError(false);
        googleLoginSuccessful(data.loginToken);
      }
    },
    onError: () => setError(true)
  });

  const handleGoogleCallback = response => {
    if (response && response.tokenId) {
      login({
        variables: {
          idToken: response.tokenId,
          language: language || 'NL',
          ...(refererUserId ? { refererUserId } : {})
        }
      });
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <GoogleLogin
        clientId="761519753164-laf90uhpk9b85ao87arilefl7nvj2n6m.apps.googleusercontent.com"
        render={renderProps => (
          <div
            role="presentation"
            onClick={() =>
              setShowPopup({
                active: true,
                loginFunction: () => renderProps.onClick()
              })
            }
            className={`${styles.socialButton} ${
              loading ? styles.loading : ''
            }`}
          >
            <img
              src={googleIcon}
              className={styles.googleIcon}
              alt="Google icon"
            />
            <span>{translations.CONTINUE_WITH_GOOGLE}</span>
          </div>
        )}
        onSuccess={handleGoogleCallback}
        onFailure={e => {
          if (!(e && e.error && e.error === 'popup_closed_by_user')) {
            setError(true);
          }
        }}
        buttonText="Login"
        cookiePolicy="single_host_origin"
      />
      {error ? (
        <div className={styles.error}>{translations.REGISTER_ERROR_GOOGLE}</div>
      ) : null}
    </div>
  );
};

export default GoogleButton;
