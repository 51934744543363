import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

import './LineChart.css';

const LineChart = props => {
  const { data } = props;
  const [svg, setSvg] = useState(null);

  useEffect(
    () => {
      if (svg) {
        drawChart(svg, props);
      }
    },
    [svg, data]
  );

  const tooltipDiv = d3.select('.tooltip-container').style('display', 'none');

  const drawChart = (svgRef, chartProps) => {
    const { parent, height } = chartProps;

    if (parent && parent.offsetWidth && svgRef) {
      const width = parent.offsetWidth;
      const xLabels = data.map(d => d.label);

      const margin = {
        top: 30,
        right: 7,
        bottom: 120,
        left: 100
      };

      const svgChart = d3
        .select(svgRef)
        .attr('width', width)
        .attr('height', height);

      svgChart.selectAll('*').remove();

      const x = d3.scaleLinear().range([0, width - margin.left - margin.right]);
      const y = d3
        .scaleLinear()
        .range([height - margin.top - margin.bottom, 0]);

      const extensionRange = 5;
      const yScaleMax = d3.max(data, d => d.value);
      const yScaleMaxExtended = yScaleMax + yScaleMax / extensionRange; // Make Y scale visually a bit higher than max number

      x.domain([0, data.length - 1]);
      y.domain([0, yScaleMaxExtended]);

      if (data && data.length) {
        const gridGroup = svgChart
          .append('g')
          .attr('class', 'grid-group')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const gridlines = d3
          .axisLeft()
          .tickFormat('')
          .tickSize(-width)
          .ticks(10)
          .scale(y);

        gridGroup
          .append('g')
          .attr('class', 'grid')
          .call(gridlines);
      }

      if (data && data.length) {
        const chartGroup = svgChart
          .append('g')
          .attr('class', 'chart-group')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const formatedData = data.map(d => ({
          label: d.label,
          value: d.value.toFixed(2)
        }));

        const valueline = d3
          .line()
          .x((d, i) => x(i))
          .y(d => y(d.value));

        chartGroup
          .append('path')
          .data([formatedData])
          .attr('class', 'line')
          .attr('d', valueline);

        chartGroup
          .append('path')
          .data([formatedData])
          .attr('class', 'line')
          .attr('d', valueline);

        chartGroup
          .selectAll('line-circle')
          .data(formatedData)
          .enter()
          .append('circle')
          .attr('class', 'data-circle')
          .attr('r', 6)
          .attr('cx', (d, i) => x(i))
          .attr('cy', d => y(d.value))
          .on('mousemove', d => {
            tooltipDiv
              .style('color', '#6f2a7f')
              .style('display', 'block')
              .style('font-family', 'Roboto Bold');

            tooltipDiv
              .html(`<strong>€${d.value}</strong>`)
              .attr('class', 'data-tooltip')
              .style('left', `${d3.event.pageX - 5}px`)
              .style('top', `${d3.event.pageY - 28}px`)
              .style('min-width', 'auto');
          })
          .on('mouseout', () => {
            tooltipDiv.style('display', 'none');
          });
      }

      const axisGroup = svgChart
        .append('g')
        .attr('class', 'axis-group')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      axisGroup
        .append('g')
        .attr(
          'transform',
          `translate(0, ${height - margin.bottom - margin.top})`
        )
        .attr('class', 'axis')
        .call(
          d3.axisBottom(x).tickFormat(d => {
            if (Number.isInteger(d)) {
              return xLabels[d];
            }
            return null;
          })
        )
        .selectAll('text')
        .attr('y', 0)
        .attr('x', 24)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(90)')
        .style('text-anchor', 'start');

      axisGroup
        .append('g')
        .attr('transform', `translate(0, 0)`)
        .attr('class', 'axis')
        .call(
          d3
            .axisLeft(y)
            .tickFormat(d => `€${d}`)
            .ticks(10)
        )
        .selectAll('text')
        .attr('x', -24)
        .attr('dy', '.35em')
        .style('text-anchor', 'end');
    }

    return null;
  };

  return (
    <div className="line-chart-container">
      {data && data.length ? (
        <svg
          ref={elem => {
            setSvg(elem);
          }}
        />
      ) : (
        <div className="no-chart-data">No data</div>
      )}
    </div>
  );
};

export default LineChart;
