import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import FileSaver from 'file-saver';

import restApi from '../../../../api';

import Loader from '../../../../campaigns/components/Loader/Loader';

import { recordActionPopups } from '../../helpers/constants';

import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';
import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';

import styles from './AccountManagementExportPopup.module.css';

export default ({
  title,
  filename,
  recordActionPopupType,
  onClose,
  recordId,
  licenceStartDate = null,
  licenceEndDate = null
}) => {
  const currentDate = new Date();
  const oneYearAgo = new Date(
    Date.UTC(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
    )
  );
  const firstDayOf2023 = new Date(Date.UTC(2023, 0, 1, 0, 0, 0));
  const defaultStartDate =
    oneYearAgo < firstDayOf2023 ? firstDayOf2023 : oneYearAgo; // Set date either one year ago or first day of 2023 (which is the min date we allow to be selected)

  const [startDate, setStartDate] = useState(
    licenceStartDate || defaultStartDate
  );
  // Return license end date if in the past else today
  const [endDate, setEndDate] = useState(
    licenceEndDate && licenceEndDate < new Date() ? licenceEndDate : new Date()
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);
    let res;
    if (recordActionPopupType === recordActionPopups.GLOBAL_BUDGET_OVERVIEW) {
      res = await restApi().generateGlobalAccountManagementBudgetOverview(
        startDate,
        endDate
      );
    } else if (recordActionPopupType === recordActionPopups.LICENSE_OVERVIEW) {
      res = await restApi().generateAccountManagementBudgetOverview(
        recordId,
        startDate,
        endDate
      );
    } else if (
      recordActionPopupType === recordActionPopups.GLOBAL_SCOPING_SURVEYS
    ) {
      res = await restApi().generateScopingSurveys(startDate, endDate);
    }
    setLoading(false);

    if (res && res.blob) {
      FileSaver.saveAs(res.blob, `${filename || 'export'}.xlsx`, {
        autoBom: true
      });

      onClose();
    } else {
      setLoading(false);
      if (res.error) {
        setError(res.error || 'Something went wrong');
      }
    }
  };

  const isAllowedToSubmit = () => {
    if (startDate > endDate) {
      setError('Start date must be before end date');
      return false;
    }
    return true;
  };

  const formatDate = d => {
    let date = d;
    if (!(date instanceof Date)) {
      date = new Date();
    }
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months start at zero
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const parseDate = dateString => {
    if (typeof dateString === 'string' && dateString.includes('/')) {
      const splitted = dateString.split('/');
      const day = parseInt(splitted[0], 10);
      const month = parseInt(splitted[1], 10);
      const year = parseInt(splitted[2], 10);
      const parsedDate = new Date(year, month - 1, day);
      if (parsedDate instanceof Date) {
        return parsedDate;
      }
    }
    return new Date();
  };

  const format = 'dd/MM/yyyy';

  const isValid = startDate && endDate;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>Select timeframe</div>
        <div className={styles.dateInputsContainer}>
          <div className={styles.dateInputWrapper}>
            <div>Start date</div>
            <DayPickerInput
              onDayChange={d => {
                setStartDate(d);
              }}
              value={startDate}
              dayPickerProps={{
                fromMonth: firstDayOf2023,
                toMonth: new Date(),
                disabledDays: d => d > currentDate
              }}
              format={format}
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder="dd/mm/yyyy"
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className={styles.dateInputWrapper}>
            <div>End date</div>
            <DayPickerInput
              onDayChange={d => {
                setEndDate(d);
              }}
              value={endDate}
              dayPickerProps={{
                fromMonth: firstDayOf2023,
                toMonth: new Date(),
                disabledDays: d => d > currentDate
              }}
              format={format}
              formatDate={formatDate}
              parseDate={parseDate}
              inputProps={{ readOnly: true }}
            />
          </div>
        </div>
        <div className={styles.note}>
          {` Note: The start and end date are included. Only surveys ${
            recordActionPopupType === recordActionPopups.GLOBAL_SCOPING_SURVEYS
              ? 'created'
              : 'first published'
          } within this timeframe will be visible in the export.`}
        </div>
      </div>
      {error ? (
        <div className={styles.errorContainer}>
          <img
            className={styles.warningErrorIcon}
            alt="Touchpoints tooltip"
            src={amWarning}
          />
          {error}
        </div>
      ) : null}
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {loading && (
            <div className={styles.loaderContainer}>
              <Loader size="small" />
            </div>
          )}
          {!loading && [
            isValid ? (
              <img
                key="submit-button"
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={async () => {
                  if (isValid) {
                    const allowedToSubmit = isAllowedToSubmit();
                    if (allowedToSubmit) {
                      setError(null);
                      await onSubmit();
                    }
                  }
                }}
                role="presentation"
              />
            ) : (
              <img
                key="submit-button"
                className={`${styles.confirm} ${
                  styles.invalid
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
              />
            ),
            <img
              key="close-button"
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          ]}
        </div>
      </div>
    </div>
  );
};
