import React from 'react';

import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <a
        className={styles.link}
        target="_blank"
        href="https://www.buffl.be/legal/privacy-policy"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <a
        className={styles.link}
        target="_blank"
        href="https://www.buffl.be/legal/terms-conditions"
        rel="noopener noreferrer"
      >
        Terms & Conditions
      </a>
      <a
        className={styles.link}
        target="_blank"
        href="https://landing.buffl.be/docs/delete_user_account.html"
        rel="noopener noreferrer"
      >
        How to delete my personal data?
      </a>
    </div>
  </div>
);

export default Footer;
