import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import AppleSignin from 'react-apple-signin-auth';

import { LOGIN_APPLE } from '../../../../../../graphql/Auth';

import appleIcon from '../../../../../../assets/img/apple-icon.svg';

import styles from './SocialSignUp.module.css';

const AppleButton = props => {
  const {
    translations,
    language,
    refererUserId,
    appleLoginSuccessful,
    setShowPopup,
    isProductionEnv
  } = props;

  const authOptions = {
    clientId: isProductionEnv ? 'be.buffl.app' : 'be.buffl.test',
    scope: 'email',
    redirectURI: isProductionEnv
      ? 'https://app.buffl.be/users/register'
      : 'https://test.buffl.be/users/register',
    state: 'state',
    nonce: '78377b525757b494427f89014f97d79928f3938d14eb51e20fb5dec9834eb304', // sha256 string 'nonce'
    usePopup: true
  };

  const [error, setError] = useState(false);

  const [login, { loading }] = useMutation(LOGIN_APPLE, {
    onCompleted: ({ loginApple: data }) => {
      if (data && data.errors && data.errors.length >= 1) {
        setError(true);
      } else if (data && data.loginToken) {
        setError(false);
        appleLoginSuccessful(data.loginToken);
      }
    },
    onError: () => {
      setError(true);
    }
  });

  const handleAppleCallback = response => {
    if (response && response.authorization && response.authorization.id_token) {
      login({
        variables: {
          identityToken: response.authorization.id_token,
          language: language || 'NL',
          nonce: 'nonce',
          applicationId: authOptions.clientId,
          authorizationCode: response.authorization.code,
          ...(refererUserId ? { refererUserId } : {}),
          webSignUp: true
        }
      });
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <AppleSignin
        authOptions={authOptions}
        onSuccess={handleAppleCallback}
        onError={e => {
          if (!(e && e.error && e.error === 'popup_closed_by_user')) {
            setError(true);
          }
        }}
        render={renderProps => (
          <div
            {...renderProps}
            role="presentation"
            onClick={() =>
              setShowPopup({
                active: true,
                loginFunction: () => renderProps.onClick()
              })
            }
            className={`${styles.socialButton} ${
              loading ? styles.loading : ''
            }`}
          >
            <img
              src={appleIcon}
              className={styles.appleIcon}
              alt="Apple icon"
            />
            <span>{translations.CONTINUE_WITH_APPLE}</span>
          </div>
        )}
      />
      {error ? (
        <div className={styles.error}>{translations.REGISTER_ERROR_APPLE}</div>
      ) : null}
    </div>
  );
};

export default AppleButton;
