import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import qs from 'query-string';

import SocialSignUp from './Steps/SocialSignUp/SocialSignUp';
import EmailSignUp from './Steps/EmailSignUp/EmailSignUp';
import Completed from './Steps/Completed/Completed';

import polyGridLeft from '../../../../assets/img/poly-grid-left.svg';
import polyGridRight from '../../../../assets/img/poly-grid-right.svg';

import styles from './SignUpForm.module.css';

import {
  GET_APP_CONFIGURATION_REGISTER,
  REGISTER,
  LOGIN,
  UPDATE_PROFILE
} from '../../../../graphql/Auth';

const mandatoryFields = ['email', 'password', 'repeatPassword'];

const SignUpForm = props => {
  const {
    location,
    history,
    translations,
    fields,
    setFields,
    signUpCompleted,
    setSignUpCompleted,
    isOrganisationUserRegister
  } = props;

  const [errorFields, setErrorFields] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    date_of_birth: '',
    gender: ''
  });
  const [registrationStarted, setRegistrationStarted] = useState(false);
  const [componentError, setComponentError] = useState(null);

  const getParameterByName = name => {
    const query = qs.parse(location.search);
    return query[name];
  };

  const addQueryParam = (field, value) => {
    const query = qs.parse(location.search);
    query[field] = value;
    history.push({ search: qs.stringify(query) });
  };

  const { data: { getAppConfiguration } = {} } = useQuery(
    GET_APP_CONFIGURATION_REGISTER,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [onRegister] = useMutation(REGISTER, {
    onCompleted: response => {
      if (
        response &&
        response.createUser &&
        response.createUser.errors &&
        response.createUser.errors.length
      ) {
        setRegistrationStarted(false);
        setComponentError(translations.EMAIL_ALREADY_USED);
      }
      if (response && response.createUser && response.createUser.done) {
        onLogin({
          variables: { email: fields.email, password: fields.password }
        });
      }
    }
  });

  const [onLogin] = useMutation(LOGIN, {
    onCompleted: response => {
      if (response && response.login && response.login.loginToken) {
        localStorage.setItem('@buffl:auth-token', response.login.loginToken);
        const fieldsToSubmit = {
          gender: fields.gender,
          dateOfBirth: fields.date_of_birth
        };
        onProfileUpdate({ variables: fieldsToSubmit });
      }
    }
  });

  const [onProfileUpdate] = useMutation(UPDATE_PROFILE, {
    onCompleted: response => {
      if (response && response.updateUser && response.updateUser.user) {
        addQueryParam('step', 'completed');
        setSignUpCompleted(true);
      }
    }
  });

  const socialLoginSuccessful = loginToken => {
    localStorage.setItem('@buffl:auth-token', loginToken);
    addQueryParam('step', 'completed');
    setSignUpCompleted(true);
  };

  const setFieldValue = (name, value) =>
    setFields({ ...fields, [name]: value });

  const submitEmailSignUp = event => {
    let error = false;
    setComponentError(null);

    const componentErrors = mandatoryFields.filter(
      field => !fields[field].trim() || fields[field].trim() === ''
    );
    const newErrorFields = {
      email: '',
      password: '',
      repeatPassword: '',
      date_of_birth: '',
      gender: ''
    };

    if (componentErrors.length) {
      error = true;
      componentErrors.forEach(cError => {
        newErrorFields[cError] = 'PLEASE_FILL_FIELD';
      });
    }

    if (!fields.date_of_birth) {
      error = true;
      newErrorFields.date_of_birth = 'PLEASE_FILL_FIELD';
    }

    if (fields.email.length) {
      // valid email
      const regexTest = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (!regexTest.test(fields.email)) {
        error = true;
        newErrorFields.email = 'PLEASE_ENTER_VALID_EMAIL';
      }
    }

    if (!fields.gender.length) {
      error = true;
      newErrorFields.gender = 'PLEASE_SELECT_GENDER';
    }

    if (
      fields.password.trim() &&
      fields.repeatPassword.trim() &&
      fields.password.trim() !== fields.repeatPassword.trim()
    ) {
      error = true;
      newErrorFields.repeatPassword = 'PASSWORDS_NOT_SAME';
    }

    setErrorFields(newErrorFields);

    if (!error) {
      setRegistrationStarted(true);
      const fieldsToSubmit = {
        email: fields.email,
        password: fields.password,
        language: fields.language,
        refererUserId: fields.refererUserId
      };
      onRegister({ variables: fieldsToSubmit });
    }

    event.preventDefault();
  };

  if (!getAppConfiguration) {
    return null;
  }

  if (getParameterByName('step') === 'completed' || signUpCompleted) {
    return (
      <div className={styles.wrapper}>
        <img src={polyGridLeft} alt="Grid left" className={styles.gridLeft} />
        <img src={polyGridRight} alt="Grid left" className={styles.gridRight} />
        <div
          className={`${styles.centralizeContent} ${styles.contentContainer}`}
        >
          <Completed translations={translations} />
        </div>
      </div>
    );
  }

  if (getParameterByName('step') === 'emailSignUp') {
    return (
      <div className={styles.wrapper}>
        <img src={polyGridLeft} alt="Grid left" className={styles.gridLeft} />
        <img src={polyGridRight} alt="Grid left" className={styles.gridRight} />
        <div className={styles.contentContainer}>
          <span
            className={styles.backButton}
            role="presentation"
            onClick={() => {
              addQueryParam('step', 'socialSignUp');
            }}
          >
            {'<'}
          </span>
          <EmailSignUp
            history={history}
            translations={translations}
            componentError={componentError}
            submitEmailSignUp={submitEmailSignUp}
            fields={fields}
            setFieldValue={setFieldValue}
            errorFields={errorFields}
            registrationStarted={registrationStarted}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <img src={polyGridLeft} alt="Grid left" className={styles.gridLeft} />
      <img src={polyGridRight} alt="Grid left" className={styles.gridRight} />
      <div className={`${styles.centralizeContent} ${styles.contentContainer}`}>
        <SocialSignUp
          location={location}
          translations={translations}
          language={fields && fields.language ? fields.language : null}
          refererUserId={
            fields && fields.refererUserId ? fields.refererUserId : null
          }
          addQueryParam={addQueryParam}
          socialLoginSuccessful={socialLoginSuccessful}
          isOrganisationUserRegister={isOrganisationUserRegister}
        />
      </div>
    </div>
  );
};

export default SignUpForm;
