import React from 'react';

import styles from './Data.module.css';

const Data = ({ data = {}, strings }) => {
  const totalEurosLeftToPay =
    (data.totalEurosCollected || 0) - (data.eurosReceivedFromBuffl || 0);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{strings.DATA}</div>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <div className={styles.name}>
            {strings.NUMBER_OF_PEOPLE_USING_APP_THROUGH_ASSOCIATION}
          </div>
          <div className={styles.value}>
            {data.numberOfUserOnTheApp || 0} {strings.PEOPLE}
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.name}>
            {strings.TOTAL_EUROS_COLLECTED_IN_BUFFL}
          </div>
          <div className={styles.value}>
            €{(data.totalEurosCollected || 0).toFixed(2)}
          </div>
        </div>
        <div className={styles.nestedTable}>
          <div className={styles.nestedTableRow}>
            <div className={styles.nestedTableDataRow}>
              <div className={styles.nestedTableRowTitle}>{strings.ACTIVE}</div>
              <div className={styles.nestedTableRowValue}>
                €{(data.totalActiveEurosCollected || 0).toFixed(2)}
              </div>
            </div>
            <div>{strings.TOKENS_DONATED_THROUGH_SHOP}</div>
          </div>
          <div className={styles.nestedTableRow}>
            <div className={styles.nestedTableDataRow}>
              <div className={styles.nestedTableRowTitle}>
                {strings.PASSIVE}
              </div>
              <div className={styles.nestedTableRowValue}>
                €{(data.totalPassiveEurosCollected || 0).toFixed(2)}
              </div>
            </div>
            <div>{strings.TOKENS_DONATED_THROUGH_COMPLETING_SURVEYS}</div>
          </div>
        </div>
        <div style={{ paddingTop: '5px' }} />
        <div className={styles.tableRow}>
          <div className={styles.name}>{strings.PAYED}</div>
          <div className={styles.value}>
            €{(data.eurosReceivedFromBuffl || 0).toFixed(2)}
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.name}>{strings.STILL_TO_PAY}</div>
          <div className={styles.value}>€{totalEurosLeftToPay.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

export default Data;
